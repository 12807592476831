<template>
    <b-card>


        <b-row>
            <h6 class="section-label mx-1 mb-2">
                Bildirimler
            </h6>

            <b-col
                cols="12"
                class="mb-2"
                v-for="(item, key) in formKeys"
                :key="'tab'+key"
            >
                <b-form-checkbox
                    :id="'checkbox'+key"
                    v-model="localOptions[item]"
                    value="1"
                    name="check-button"
                    switch
                    inline
                >
                    <span>{{ formLabels[item] }}</span>
                </b-form-checkbox>
            </b-col>

            <b-col cols="12">
                <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    class="mt-1 mr-1"
                    @click.prevent="updateUser"
                    :disabled="loading"
                >
                    Değişiklikleri Kaydet
                </b-button>
                <b-button
                    v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                    type="reset"
                    class="mt-1"
                    variant="outline-secondary"
                    @click.prevent="resetForm"
                    :disabled="loading"
                >
                    Sıfırla
                </b-button>
            </b-col>

        </b-row>

    </b-card>
</template>

<script>
import {
    BButton, BRow, BCol, BCard, BFormCheckbox,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

import store from '@/store'
import FormData from 'form-data'
import ToastificationContent from '@core/components/toastification/ToastificationContent'

export default {
    name: "UserNotification",
    components: {
        BButton,
        BRow,
        BCol,
        BCard,
        BFormCheckbox,
    },
    directives: {
        Ripple,
    },
    props: {
        userData: {
            type: Object,
            default: () => {},
        },
    },
    data() {
        return {
            localOptions: JSON.parse(JSON.stringify(this.userData)),
            loading: false,
            formKeys: ['pushNotification', 'smsNotification', 'emailNotification', 'campaignNotification'],
            formLabels: {
                pushNotification: 'Uygulama İçi Bildirimler',
                smsNotification: 'SMS Bildirimler',
                emailNotification: 'E-Posta Bildirimler',
                campaignNotification: 'Kampanya Bildirimleri',
            },
        }
    },
    methods: {
        resetForm() {
            this.localOptions = JSON.parse(JSON.stringify(this.userData))
        },
        updateUser(){
            let t = this
            let data = new FormData()
            let item = t.localOptions
            let itemKeys = t.formKeys
            for(let i = 0; i<itemKeys.length; i++){
                let key = itemKeys[i]
                data.append(key, item[key])
            }

            if(!t.loading){
                t.loading = true
                store.commit('app/SET_LOADING', true)
                t.$http.post('admin/user/updateUser/'+t.$route.params.id, data).then(response=>{
                    const res = response.data
                    const {data} = res

                    if(res.status){
                        if(res.message){
                            t.$toast({
                                component: ToastificationContent,
                                position: 'top-right',
                                props: {
                                    title: 'Başarılı!',
                                    icon: 'CheckIcon',
                                    variant: 'success',
                                    text: res.message
                                },
                            })
                        }
                        t.userData = structuredClone(t.localOptions)
                    }else{
                        if(res.message){
                            t.$toast({
                                component: ToastificationContent,
                                position: 'top-right',
                                props: {
                                    title: 'Hata!',
                                    icon: 'AlertCircleIcon',
                                    variant: 'danger',
                                    text: res.message
                                },
                            })
                        }
                        if(res.error){

                        }
                    }

                }).catch(err=>{console.log(err)}).then(f=>{
                    t.loading = false
                    store.commit('app/SET_LOADING', false)
                })
            }

        }
    },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
