<template>
    <b-card>

        <b-media no-body>
            <b-media-aside>
                <b-avatar
                    rounded
                    :src="$basePath+localOptions.fileName"
                    size="80px"
                    :text="avatarText(userData.name+' '+userData.surname)"
                />
            </b-media-aside>
        </b-media>

        <!-- form -->
        <b-form @submit.prevent="updateUser" class="mt-2">

            <b-row>

                <b-col md="6">
                    <b-form-group
                        label-for="name"
                        label="Adı"
                    >
                        <b-form-input
                            id="name"
                            v-model="localOptions.name"
                            placeholder="Adı"
                        />
                    </b-form-group>
                </b-col>
                <b-col md="6">
                    <b-form-group
                        label-for="surname"
                        label="Soyadı"
                    >
                        <b-form-input
                            id="surname"
                            v-model="localOptions.surname"
                            placeholder="Soyadı"
                        />
                    </b-form-group>
                </b-col>
                <b-col md="6">
                    <b-form-group
                        label-for="identityNumber"
                        label="Kimlik No"
                    >
                        <b-form-input
                            id="identityNumber"
                            v-model="localOptions.identityNumber"
                            placeholder="Kimlik No"
                        />
                    </b-form-group>
                </b-col>
                <b-col md="6">
                    <b-form-group
                        label-for="birthday"
                        label="Doğum Tarihi"
                    >
                        <b-form-input
                            id="birthday"
                            v-model="localOptions.birthday"
                            placeholder="Doğum Tarihi"
                        />
                    </b-form-group>
                </b-col>

                <b-col md="6">
                    <b-form-group
                        label-for="email"
                        label="E-Posta Adresi"
                    >
                        <b-form-input
                            id="email"
                            v-model="localOptions.email"
                            placeholder="E-Posta Adresi"
                        />
                    </b-form-group>
                </b-col>

                <b-col md="6">
                    <b-form-group
                        label-for="gsm"
                        label="GSM Telefon Numarası"
                    >
                        <cleave
                            id="gsm"
                            v-model="localOptions.gsm"
                            class="form-control"
                            :raw="false"
                            :options="clevePhone"
                            placeholder="GSM Telefon Numarası"
                        />
                    </b-form-group>
                </b-col>

                <b-col md="6">
                    <b-form-group
                        label-for="status"
                        label="Hesap Aktivasyon Durumu"
                    >
                        <v-select
                            id="status"
                            v-model="localOptions.status"
                            label="title"
                            :clearable="false"
                            :reduce="item=>item.value"
                            :options="[{title:'Aktif', value:'1'}, {title:'Pasif', value:'0'}]"
                        />
                    </b-form-group>
                </b-col>

                <b-col md="6">
                    <b-form-group
                        label-for="type"
                        label="Kullanıcı Türü"
                    >
                        <v-select
                            v-model="localOptions.type"
                            :options="typeFilterItems"
                            label="title"
                            :reduce="item=>item.value"
                            :clearable="false"
                            placeholder="Kullanıcı Türü"
                            disabled
                        />
                    </b-form-group>
                </b-col>



                <b-col cols="12">
                    <b-button
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        variant="primary"
                        class="mt-1 mr-1"
                        type="submit"
                        :disabled="loading"
                    >
                        Değişiklikleri Kaydet
                    </b-button>
                    <b-button
                        v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                        type="reset"
                        class="mt-1"
                        variant="outline-secondary"
                        @click.prevent="resetForm"
                        :disabled="loading"
                    >
                        Sıfırla
                    </b-button>
                </b-col>
            </b-row>
        </b-form>
    </b-card>
</template>

<script>
import {
    BButton, BForm, BFormGroup, BFormInput, BRow, BCol, BCard, BFormTextarea, BAvatar,
    BImg,
    BMedia,
    BMediaAside,
    BLink,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import Ripple from 'vue-ripple-directive'
import Cleave from 'vue-cleave-component'
import store from '@/store'
import FormData from 'form-data'
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import {avatarText, title} from '@core/utils/filter'

// eslint-disable-next-line import/no-extraneous-dependencies
import 'cleave.js/dist/addons/cleave-phone.tr'

export default {
    components: {
        BButton,
        BForm,
        BFormGroup,
        BFormInput,
        BRow,
        BCol,
        BCard,
        BFormTextarea,
        vSelect,
        flatPickr,
        Cleave,
        BImg,
        BMedia,
        BMediaAside,
        BLink,
        BAvatar,
    },
    directives: {
        Ripple,
    },
    props: {
        userData: {
            type: Object,
            default: () => {},
        },
    },
    data() {
        return {
            localOptions: JSON.parse(JSON.stringify(this.userData)),
            clevePhone: {
                phone: true,
                phoneRegionCode: 'TR',
            },
            typeFilterItems: [{label:'Yükleniyor...'}],
            loading: false,
            formKeys: ['name', 'surname', 'identityNumber', 'birthday', 'email', 'status', 'gsm'],
            avatarText
        }
    },
    created(){
        let t = this
        t.$http.get('admin/user/getRoles').then(res=>{
            let {data} = res.data
            let items = []
            for(let i = 0; i<data.length; i++){
                items.push({title: data[i].name, value: data[i].type})
            }
            t.typeFilterItems = items
        }).catch(err=>{
            console.log(err)
        }).then(f=>{
        })
    },
    methods: {
        resetForm() {
            this.localOptions = JSON.parse(JSON.stringify(this.userData))
        },
        updateUser(){
            let t = this
            let data = new FormData()
            let item = t.localOptions
            let itemKeys = t.formKeys
            for(let i = 0; i<itemKeys.length; i++){
                let key = itemKeys[i]
                data.append(key, item[key])
            }

            if(!t.loading){
                t.loading = true
                store.commit('app/SET_LOADING', true)
                t.$http.post('admin/user/updateUser/'+t.$route.params.id, data).then(response=>{
                    const res = response.data
                    const {data} = res

                    if(res.status){
                        if(res.message){
                            t.$toast({
                                component: ToastificationContent,
                                position: 'top-right',
                                props: {
                                    title: 'Başarılı!',
                                    icon: 'CheckIcon',
                                    variant: 'success',
                                    text: res.message
                                },
                            })
                        }
                        t.userData = structuredClone(t.localOptions)
                    }else{
                        if(res.message){
                            t.$toast({
                                component: ToastificationContent,
                                position: 'top-right',
                                props: {
                                    title: 'Hata!',
                                    icon: 'AlertCircleIcon',
                                    variant: 'danger',
                                    text: res.message
                                },
                            })
                        }
                        if(res.error){

                        }
                    }

                }).catch(err=>{console.log(err)}).then(f=>{
                    t.loading = false
                    store.commit('app/SET_LOADING', false)
                })
            }

        }
    },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
