<template>
    <b-card>
        <!-- form -->
        <b-form @submit.prevent="updatePassword">
            <b-row>
                <!-- new password -->
                <b-col md="6">
                    <b-form-group
                        label-for="account-new-password"
                        label="Yeni Şifre"
                    >
                        <b-input-group class="input-group-merge">
                            <b-form-input
                                id="account-new-password"
                                v-model="newPasswordValue"
                                :type="passwordFieldTypeNew"
                                name="new-password"
                                placeholder="Yeni Şifre"
                            />
                            <b-input-group-append is-text>
                                <feather-icon
                                    :icon="passwordToggleIconNew"
                                    class="cursor-pointer"
                                    @click="togglePasswordNew"
                                />
                            </b-input-group-append>
                        </b-input-group>
                    </b-form-group>
                </b-col>
                <!--/ new password -->
            </b-row>
            <b-row>


                <!-- retype password -->
                <b-col md="6">
                    <b-form-group
                        label-for="account-retype-new-password"
                        label="Yeni Şifre Tekrar"
                    >
                        <b-input-group class="input-group-merge">
                            <b-form-input
                                id="account-retype-new-password"
                                v-model="RetypePassword"
                                :type="passwordFieldTypeRetype"
                                name="retype-password"
                                placeholder="Yeni Şifre Tekrar"
                            />
                            <b-input-group-append is-text>
                                <feather-icon
                                    :icon="passwordToggleIconRetype"
                                    class="cursor-pointer"
                                    @click="togglePasswordRetype"
                                />
                            </b-input-group-append>
                        </b-input-group>
                    </b-form-group>
                </b-col>
                <!--/ retype password -->

                <!-- buttons -->
                <b-col cols="12">
                    <b-button
                        type="submit"
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        variant="primary"
                        class="mt-1 mr-1"
                        :disabled="loading"
                    >
                        Kaydet
                        <b-spinner label="Loading..." v-if="loading" />
                    </b-button>
                </b-col>
                <!--/ buttons -->
            </b-row>
        </b-form>
    </b-card>
</template>

<script>
import {
    BButton, BForm, BFormGroup, BFormInput, BRow, BCol, BCard, BInputGroup, BInputGroupAppend, BSpinner,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
    components: {
        BButton,
        BForm,
        BFormGroup,
        BFormInput,
        BRow,
        BCol,
        BCard,
        BInputGroup,
        BInputGroupAppend,
        BSpinner,
    },
    directives: {
        Ripple,
    },
    data() {
        return {
            newPasswordValue: '',
            RetypePassword: '',
            passwordFieldTypeNew: 'password',
            passwordFieldTypeRetype: 'password',
            loading: false
        }
    },
    computed: {
        passwordToggleIconNew() {
            return this.passwordFieldTypeNew === 'password' ? 'EyeIcon' : 'EyeOffIcon'
        },
        passwordToggleIconRetype() {
            return this.passwordFieldTypeRetype === 'password' ? 'EyeIcon' : 'EyeOffIcon'
        },
    },
    methods: {
        togglePasswordNew() {
            this.passwordFieldTypeNew = this.passwordFieldTypeNew === 'password' ? 'text' : 'password'
        },
        togglePasswordRetype() {
            this.passwordFieldTypeRetype = this.passwordFieldTypeRetype === 'password' ? 'text' : 'password'
        },
        updatePassword(){
            let t = this
            let formData = {
                nPassword: t.newPasswordValue,
                rPassword: t.RetypePassword
            }
            t.loading = true

            t.$http.post('admin/user/setPassword/'+t.$route.params.id, formData).then(response=>{
                const res = response.data
                if(res.status){
                    t.$toast({
                        component: ToastificationContent,
                        props: {
                            title: 'Başarılı',
                            icon: 'CheckIcon',
                            variant: 'success',
                            text: res.message
                        },
                    })
                    t.newPasswordValue = ''
                    t.RetypePassword = ''
                }else{
                    t.$toast({
                        component: ToastificationContent,
                        props: {
                            title: 'Hata',
                            icon: 'AlertTriangleIcon',
                            variant: 'danger',
                            text: res.message
                        },
                    })
                }
            }).catch(err=>{console.log(err)}).then(f=>{
                t.loading = false
            })
        }
    },
}
</script>
