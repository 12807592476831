<template>
    <div>
        <b-alert :variant="loading?'primary':'danger'" :show="!user">
            <div class="alert-body py-3 px-2">
                <span><strong v-if="loading">Veriler yükleniyor. <b-spinner label="Loading..." /></strong><strong v-else>Veri bulunamadı.</strong></span>
            </div>
        </b-alert>
        <b-tabs
            v-if="user"
            vertical
            content-class="col-12 col-md-9 mt-1 mt-md-0"
            pills
            nav-wrapper-class="col-md-3 col-12"
            nav-class="nav-left"
        >
            <b-tab active>

                <!-- title -->
                <template #title>
                    <feather-icon
                        icon="UserIcon"
                        size="18"
                        class="mr-50"
                    />
                    <span class="font-weight-bold">Kullanıcı Bilgileri</span>
                </template>

                <user-detail :userData="user.user" />


            </b-tab>

            <b-tab>
                <template #title>
                    <feather-icon
                        icon="LockIcon"
                        size="18"
                        class="mr-50"
                    />
                    <span class="font-weight-bold">Şifre Güncelle</span>
                </template>

                <user-password-change />
            </b-tab>

            <b-tab>
                <template #title>
                    <feather-icon
                        icon="BellIcon"
                        size="18"
                        class="mr-50"
                    />
                    <span class="font-weight-bold">Bildirimler</span>
                </template>

                <user-notification :user-data="user.user" />
            </b-tab>

            <b-tab v-if="user.company">
                <template #title>
                    <feather-icon
                        icon="BriefcaseIcon"
                        size="18"
                        class="mr-50"
                    />
                    <span class="font-weight-bold">Firma Bilgileri</span>
                </template>

                <user-company :comp-data="user.company" />
            </b-tab>

        </b-tabs>
    </div>
</template>

<script>
import {
    BTabs,
    BTab,
    BAlert,
    BSpinner,
} from 'bootstrap-vue'
import userDetail from "./Details/UserDetail"
import userPasswordChange from "./Details/UserPasswordChange"
import userCompany from "./Details/UserCompany"
import userNotification from "./Details/UserNotification"

export default {
    name: "detail",
    components: {
        BTabs,
        BTab,
        BAlert,
        BSpinner,

        userDetail,
        userPasswordChange,
        userCompany,
        userNotification,
    },
    data(){
        return {
            user: false,
            loading: true
        }
    },
    created(){
        let t = this
        t.$http.get('admin/user/getUserDetail/'+t.$route.params.id).then(res=>{
            const {data} = res
            if(data.status){
                t.user = data.data
            }
        }).catch(err=>{
            console.log(err)
        }).then(f=>{
            t.loading = false
        })
    },
    methods:{

    }
}
</script>

<style scoped lang="scss">

</style>
