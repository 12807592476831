<template>
    <b-card>

        <b-media no-body>
            <b-media-aside>
                <b-avatar
                    rounded
                    :src="$basePath+localOptions.logo"
                    size="80px"
                    :text="avatarText(compData.companyName)"
                />
            </b-media-aside>
        </b-media>

        <b-form class="mt-2" @submit.prevent="updateCompany">
            <b-row>

                <b-col md="6">
                    <b-form-group
                        label-for="companyName"
                        label="Firma Adı"
                    >
                        <b-form-input
                            id="companyName"
                            v-model="localOptions.companyName"
                            placeholder="Firma Adı"
                        />
                    </b-form-group>
                </b-col>
                <b-col md="6">
                    <b-form-group
                        label-for="website"
                        label="Websitesi"
                    >
                        <b-form-input
                            id="website"
                            v-model="localOptions.website"
                            placeholder="Websitesi"
                        />
                    </b-form-group>
                </b-col>

                <b-col md="6">
                    <b-form-group
                        label-for="openingHour"
                        label="Açılış Saati"
                    >
                        <b-form-input
                            id="openingHour"
                            v-model="localOptions.openingHour"
                            placeholder="Açılış Saati"
                        />
                    </b-form-group>
                </b-col>
                <b-col md="6">
                    <b-form-group
                        label-for="closingHour"
                        label="Kapanış Saati"
                    >
                        <b-form-input
                            id="closingHour"
                            v-model="localOptions.closingHour"
                            placeholder="Kapanış Saati"
                        />
                    </b-form-group>
                </b-col>

                <b-col md="6">
                    <b-form-group
                        label-for="email"
                        label="E-Posta Adresi"
                    >
                        <b-form-input
                            id="email"
                            v-model="localOptions.email"
                            placeholder="E-Posta Adresi"
                        />
                    </b-form-group>
                </b-col>
                <b-col md="6">
                    <b-form-group
                        label-for="gsm"
                        label="GSM Telefon Numarası"
                    >
                        <cleave
                            id="gsm"
                            v-model="localOptions.gsm"
                            class="form-control"
                            :raw="false"
                            :options="clevePhone"
                            placeholder="GSM Telefon Numarası"
                        />
                    </b-form-group>
                </b-col>

                <b-col md="6">
                    <b-form-group
                        label-for="showOnMap"
                        label="Haritada Göster"
                    >
                        <v-select
                            id="showOnMap"
                            v-model="localOptions.showOnMap"
                            label="title"
                            :reduce="item=>item.value"
                            :clearable="false"
                            :options="[{title:'Aktif', value:'1'}, {title:'Pasif', value:'0'}]"
                        />
                    </b-form-group>
                </b-col>
                <b-col md="6">
                    <b-form-group
                        label-for="type"
                        label="Firma Türü"
                    >
                        <v-select
                            v-model="localOptions.type"
                            :options="typeFilterItems"
                            label="title"
                            :reduce="item=>item.value"
                            :clearable="false"
                            placeholder="Firma Türü"
                            disabled
                        />
                    </b-form-group>
                </b-col>

                <b-col cols="12">
                    <b-form-group
                        label="Adres"
                        label-for="address"
                    >
                        <b-form-textarea
                            id="address"
                            v-model="localOptions.address"
                            rows="4"
                            placeholder="Adres"
                        />
                    </b-form-group>
                </b-col>


                <b-col md="6">
                    <b-form-group
                        label-for="cityId"
                        label="Şehir"
                    >
                        <v-select
                            id="cityId"
                            v-model="localOptions.cityId"
                            label="name"
                            :reduce="item=>item.id"
                            :clearable="false"
                            :loading="!loaders.cities"
                            :options="cities"
                        />
                    </b-form-group>
                </b-col>
                <b-col md="6">
                    <b-form-group
                        label-for="countyId"
                        label="İlçe"
                    >
                        <v-select
                            id="countyId"
                            v-model="localOptions.countyId"
                            label="name"
                            :reduce="item=>item.id"
                            :clearable="false"
                            :loading="!loaders.counties"
                            :options="counties"
                        />
                    </b-form-group>
                </b-col>
                <b-col md="6">
                    <b-form-group
                        label-for="districtId"
                        label="Mahalle"
                    >
                        <v-select
                            id="districtId"
                            v-model="localOptions.districtId"
                            label="name"
                            :reduce="item=>item.id"
                            :clearable="false"
                            :loading="!loaders.districts"
                            :options="districts"
                        />
                    </b-form-group>
                </b-col>
                <b-col md="6">
                    <b-form-group
                        label-for="streetId"
                        label="Sokak"
                    >
                        <v-select
                            id="streetId"
                            v-model="localOptions.streetId"
                            label="name"
                            :reduce="item=>item.id"
                            :clearable="false"
                            :loading="!loaders.streets"
                            :options="streets"
                        />
                    </b-form-group>
                </b-col>

                <b-col md="6">
                    <b-form-group
                        label-for="latitude"
                        label="Koordinat(lat)"
                    >
                        <b-form-input
                            id="latitude"
                            v-model="localOptions.latitude"
                            placeholder="Koordinat(lat)"
                        />
                    </b-form-group>
                </b-col>
                <b-col md="6">
                    <b-form-group
                        label-for="longitude"
                        label="Koordinat(lon)"
                    >
                        <b-form-input
                            id="longitude"
                            v-model="localOptions.longitude"
                            placeholder="Koordinat(lon)"
                        />
                    </b-form-group>
                </b-col>

                <b-col cols="12">
                    <b-form-group
                        label="Hakkında"
                        label-for="about"
                    >
                        <b-form-textarea
                            id="about"
                            v-model="localOptions.about"
                            rows="4"
                            placeholder="Hakkında"
                        />
                    </b-form-group>
                </b-col>



                <b-col cols="12">
                    <b-button
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        variant="primary"
                        class="mt-1 mr-1"
                        type="submit"
                        :disabled="loading"
                    >
                        Değişiklikleri Kaydet
                    </b-button>
                    <b-button
                        v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                        type="reset"
                        class="mt-1"
                        variant="outline-secondary"
                        @click.prevent="resetForm"
                        :disabled="loading"
                    >
                        Sıfırla
                    </b-button>
                </b-col>
            </b-row>
        </b-form>
    </b-card>
</template>

<script>
import {
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BFormTextarea,
    BAlert,
    BSpinner,
    BImg,
    BMedia,
    BMediaAside, BLink, BAvatar,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import Ripple from 'vue-ripple-directive'
import Cleave from 'vue-cleave-component'
import store from '@/store'
import FormData from 'form-data'
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import {avatarText, title} from '@core/utils/filter'

// eslint-disable-next-line import/no-extraneous-dependencies
import 'cleave.js/dist/addons/cleave-phone.tr'

export default {
    components: {
        BButton,
        BForm,
        BFormGroup,
        BFormInput,
        BRow,
        BCol,
        BCard,
        BFormTextarea,
        vSelect,
        flatPickr,
        Cleave,
        BAlert,
        BSpinner,
        BImg,
        BMedia,
        BMediaAside,
        BLink,
        BAvatar,
    },
    directives: {
        Ripple,
    },
    props: {
        compData: {
            type: Object,
            default: () => {},
        },
    },
    data() {
        return {
            localOptions: structuredClone(this.compData),
            clevePhone: {
                phone: true,
                phoneRegionCode: 'TR',
            },
            typeFilterItems: [{label:'Yükleniyor...'}],
            loading: false,
            loaders: {
                roles: false,
                cities: false,
                counties: false,
                districts: false,
                streets: false
            },
            formKeys: ['companyName', 'website', 'openingHour', 'closingHour', 'email', 'gsm', 'showOnMap', 'address', 'cityId',  'countyId', 'districtId', 'streetId', 'latitude', 'longitude', 'about'],
            cities: [],
            counties: [],
            districts: [],
            streets: [],
            avatarText
        }
    },
    created(){
        let t = this
        t.$http.get('admin/user/getRoles').then(res=>{
            let {data} = res.data
            let items = []
            for(let i = 0; i<data.length; i++){
                if(parseInt(data[i].type)>1 && parseInt(data[i].type)<100){
                    items.push({title: data[i].name, value: data[i].type})
                }
            }
            t.typeFilterItems = items
        }).catch(err=>{
            console.log(err)
        }).then(f=>{
            t.loaders.roles = true
        })

        t.$http.get('misc/general/getCities').then(response=>{
            const res = response.data
            const {data} = res

            if(res.status){
                t.cities = data
            }
        }).catch(err=>{console.log(err)}).then(f=>{
            t.loaders.cities = true
        })

        this.setCounties()
        this.setDistricts()
        this.setStreets()

    },
    computed:{
        checkLoaders(){
            let keys = Object.keys(this.loaders)
            let status = true
            for(let i = 0; i < keys.length; i++){
                status = !this.loaders[keys[i]]?false:status
            }
            return status
        }
    },
    watch:{
        'localOptions.cityId'(val, oldVal){
            this.localOptions.countyId = val===this.compData.cityId && this.localOptions.countyId===this.compData.countyId ? this.localOptions.countyId : null
            this.setCounties()
        },
        'localOptions.countyId'(val, oldVal){
            this.localOptions.districtId = val===this.compData.countyId && this.localOptions.districtId===this.compData.districtId ? this.localOptions.districtId : null
            this.setDistricts()
        },
        'localOptions.districtId'(val, oldVal){
            this.localOptions.streetId = val===this.compData.districtId && this.localOptions.streetId===this.compData.streetId ? this.localOptions.streetId : null
            this.setStreets()
        },
    },
    methods: {
        resetForm() {
            this.localOptions = JSON.parse(JSON.stringify(this.compData))
        },
        updateCompany(){
            let t = this
            let data = new FormData()
            let item = t.localOptions
            let itemKeys = t.formKeys
            for(let i = 0; i<itemKeys.length; i++){
                let key = itemKeys[i]
                data.append(key, item[key])
            }

            if(!t.loading){
                t.loading = true
                store.commit('app/SET_LOADING', true)
                t.$http.post('admin/user/updateUserCompany/'+t.compData.id, data).then(response=>{
                    const res = response.data
                    const {data} = res

                    if(res.status){
                        if(res.message){
                            t.$toast({
                                component: ToastificationContent,
                                position: 'top-right',
                                props: {
                                    title: 'Başarılı!',
                                    icon: 'CheckIcon',
                                    variant: 'success',
                                    text: res.message
                                },
                            })
                        }
                        t.compData = structuredClone(t.localOptions)
                    }else{
                        if(res.message){
                            t.$toast({
                                component: ToastificationContent,
                                position: 'top-right',
                                props: {
                                    title: 'Hata!',
                                    icon: 'AlertCircleIcon',
                                    variant: 'danger',
                                    text: res.message
                                },
                            })
                        }
                        if(res.error){

                        }
                    }

                }).catch(err=>{console.log(err)}).then(f=>{
                    t.loading = false
                    store.commit('app/SET_LOADING', false)
                })
            }

        },
        async setCounties(){
            let t = this
            t.loaders.counties = false
            if(t.localOptions.cityId){
                t.$http.get('misc/general/getCounties/'+t.localOptions.cityId).then(response=>{
                    const res = response.data
                    const {data} = res

                    if(res.status){
                        t.counties = data
                    }
                }).catch(err=>{console.log(err)}).then(f=>{
                    t.loaders.counties = true
                })
            }else{
                t.counties = []
                t.loaders.counties = true
            }
        },
        async setDistricts(){
            let t = this
            t.loaders.districts = false
            if(t.localOptions.countyId){
                t.$http.get('misc/general/getDistricts/'+t.localOptions.countyId).then(response=>{
                    const res = response.data
                    const {data} = res

                    if(res.status){
                        t.districts = data
                    }
                }).catch(err=>{console.log(err)}).then(f=>{
                    t.loaders.districts = true
                })
            }else{
                t.districts = []
                t.loaders.districts = true
            }
        },
        async setStreets(){
            let t = this
            t.loaders.streets = false
            if(t.localOptions.districtId){
                t.$http.get('misc/general/getStreets/'+t.localOptions.districtId).then(response=>{
                    const res = response.data
                    const {data} = res

                    if(res.status){
                        t.streets = data
                    }
                }).catch(err=>{console.log(err)}).then(f=>{
                    t.loaders.streets = true
                })
            }else{
                t.streets = []
                t.loaders.streets = true
            }
        },
    },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
